import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { config } from '@legacyApp/client/config';
import routingService from '@legacyApp/client/modules/app/routingService';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { trans, Trans } from '@legacyApp/client/modules/translation/translate';

import { Image, parseImageSrc } from '@common/components/default/Image/Image';
import { LinkTo } from '@common/components/default/LinkTo/LinkTo';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import {
	FooterColumnStyle,
	FooterLabelStyled,
	FooterRowStyle,
	FooterTextStyle,
} from '@common/components/footer/Footer.styled';
import Icon from '@common/components/icons/Icon';
import { EmLicense } from '@legacyApp/client/components/license/emLicense';
import { License } from '@legacyApp/client/components/license/license';
import { RngLicence } from '@legacyApp/client/components/license/rngLicence';
import { SupportButton } from '@modules/support/components/SupportButton';
import { ButtonStyled } from '@ui/button/Button.styled';

const getLinks = (array) => {
	return array
		.filter((el) => !el.disabled)
		.map((el) => {
			const button = (
				<div className="text-style-xs-medium">
					{trans({ label: el.name() })}
				</div>
			);

			if (el.url) {
				return (
					<Redirect
						target="_blank"
						rel={`noopener noreferrer ${el.nofollow ? 'nofollow' : ''}`}
						href={el.url}
						key={el.id}
					>
						{button}
					</Redirect>
				);
			}

			return (
				<LinkTo
					key={el.id}
					href={routingService.to(el.id)}
					forceATag
					{...(el.nofollow ? { rel: 'nofollow' } : {})}
				>
					{button}
				</LinkTo>
			);
		});
};

const PRO = getLinks([
	{
		id: 'blog',
		name: () => 'Blog',
		url: `https://${config.domain}/blog/`,
	},
	{
		id: ROUTING_ID.TERMS,
		name: () => 'Terms',
		url: parseImageSrc('/documents/Terms.pdf'),
		nofollow: true,
	},
	{
		id: 'sports_rules',
		name: () => 'Sports Rules',
		url: parseImageSrc('/documents/Sports_rules.pdf'),
		nofollow: true,
	},
	{
		id: 'be_gamble_aware',
		name: () => 'Gamble Aware',
		url: 'https://www.begambleaware.org/',
		nofollow: true,
	},
	{
		id: ROUTING_ID.FAQ,
		name: () => 'FAQ',
	},
	{
		id: 'api',
		name: () => 'API',
		url: config.documentationUrl,
	},
]);

const CURRENT_YEAR = new Date().getFullYear();

export const FooterBottomRow: FC = () => {
	return (
		<FooterBottomRowStyled>
			<FooterBrandColumn>
				<FooterLogoStyle
					className="text-logo"
					href={routingService.to(ROUTING_ID.HOME)}
				>
					<>
						<Icon id="wolf" /> {config.logo}
					</>
				</FooterLogoStyle>

				<FooterTextStyle className="text-style-caption-regular">
					{CURRENT_YEAR} {trans({ label: 'All rights reserved' })}
				</FooterTextStyle>

				<FooterTextStyle className="text-style-caption-regular">
					{trans({
						label: `{{name}} is owned and operated by {{companyName}}, registration number: {{number}},
registered address: {{address}}. Contact us {{contactEmail}}. {{name}} is licensed and
regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and
operates under License No. {{licenseNumber}}. {{name}} has passed all regulatory compliance
and is legally authorized to conduct gaming operations for any and all games of chance and wagering.`,
						options: {
							name: config.appName,
							companyName: 'PixelPulse N.V.',
							address: 'Abraham de Veerstraat 9, Willemstad, Curaçao',
							number: '165612',
							licenseNumber: 'ALSI-092404018-FI2',
							contactEmail: `support@${config.domain}`,
						},
					})}
					{/*{trans({*/}
					{/*	label:*/}
					{/*		'Payment agent company is {{companyName}} with address {{address}} and Registration number: {{number}}',*/}
					{/*	options: {*/}
					{/*		companyName: 'CHALEN INNOVATIONS LIMITED',*/}
					{/*		address:*/}
					{/*			'Griva Digeni, 81 MARINOS COURT, 3rd floor, Flat/Office 301 6043, Larnaca, Cyprus',*/}
					{/*		number: 'HE 454253',*/}
					{/*	},*/}
					{/*})}*/}
				</FooterTextStyle>

				<FooterTextStyle className="text-style-caption-regular">
					{trans({
						label:
							'This site is protected by reCAPTCHA and the Google <1>Privacy Policy</1> and <3>Terms of Service</3> apply.',
						components: (
							<Fragment>
								This site is protected by reCAPTCHA and the Google
								<Redirect
									target="_blank"
									rel="noopener noreferrer"
									href="https://policies.google.com/privacy"
								>
									Privacy Policy
								</Redirect>
								and
								<Redirect
									target="_blank"
									rel="noopener noreferrer"
									href="https://policies.google.com/terms"
								>
									Terms of Service
								</Redirect>
								apply.
							</Fragment>
						),
					})}
				</FooterTextStyle>
			</FooterBrandColumn>

			<FooterContactColumn>
				<FooterContactBox>
					<FooterLabelStyled>
						<Trans label={'Marketing'} />
					</FooterLabelStyled>

					<FooterTextStyle className="text-style-xs-regular">
						marketing@{config.domain}
					</FooterTextStyle>
				</FooterContactBox>

				<FooterContactBox>
					<FooterLabelStyled>
						<Trans label={'Support'} />
					</FooterLabelStyled>

					<FooterTextStyle className="text-style-xs-regular">
						support@{config.domain}
					</FooterTextStyle>
				</FooterContactBox>

				<FooterContactBox>
					<FooterTextStyle className="text-style-xs-regular">
						<FooterSupportButton>
							<SupportButton />
						</FooterSupportButton>
					</FooterTextStyle>
				</FooterContactBox>
			</FooterContactColumn>

			<FooterLinksColumn>
				<FooterLabelStyled>
					<Trans label={'Policy'} />
				</FooterLabelStyled>

				{PRO}
			</FooterLinksColumn>

			<FooterLicenseColumn>
				<div className="license">
					<Image src={'/img/footer/18.svg'} alt="18+" />
				</div>

				<div className="license">
					<EmLicense />
				</div>

				<div className="license">
					<RngLicence />
				</div>

				<License />
			</FooterLicenseColumn>
		</FooterBottomRowStyled>
	);
};

const FooterSupportButton = styled.div``;

export const FooterContactBox = styled.div`
	&:not(:last-of-type) {
		margin-bottom: 24px;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_tablet_large}) {
		${FooterSupportButton} {
			width: 100%;

			${ButtonStyled} {
				justify-content: center;
				padding-left: 4px;
				padding-right: 4px;
				width: 100%;
			}
		}
	}
`;

export const FooterLicenseColumn = styled(FooterColumnStyle)`
	align-items: center;
	flex-direction: row;
	height: 80px;
	justify-content: flex-end;
	padding: 5px 0;

	div,
	a {
		align-items: center;
		display: flex !important;
		flex-direction: row;
		height: 100% !important;
		max-height: 100% !important;
		max-width: 100% !important;
		width: auto !important;
	}

	.license {
		margin: 0 5px;
		min-width: 60px;

		img {
			height: auto !important;
			max-height: 100%;
			max-width: 100%;
			width: auto !important;
		}
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_medium,
						b: viewportPadding,
					})}) {
				justify-content: space-between;
			}
		`,
	)}
`;

export const FooterLogoStyle = styled(LinkTo)<{
	className?: string;
}>`
	align-items: center;
	display: flex;
	font-size: 22px;
	justify-content: flex-start;
	margin-bottom: 16px;

	svg {
		height: 26px;
		margin-right: 3px;
		width: auto;
	}
`;

export const FooterBrandColumn = styled(FooterColumnStyle)`
	${FooterTextStyle} {
		color: var(--color-dark-200);

		&:not(:last-of-type) {
			margin-bottom: 12px;
		}
	}
`;

export const FooterContactColumn = styled(FooterColumnStyle)`
	gap: 12px;

	${FooterTextStyle} {
		color: var(--color-dark-50);
	}

	${FooterLabelStyled} {
		margin-bottom: 4px;
	}

	${FooterContactBox} {
		min-width: 140px;
	}
`;

export const FooterLinksColumn = styled(FooterColumnStyle)`
	> a {
		&:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}
`;

export const FooterBottomRowStyled = styled(FooterRowStyle)`
	display: grid;
	flex-flow: row;
	gap: 40px;
	grid-template-columns: calc(40% - 30px) calc(20% - 30px) calc(10% - 30px) calc(
			30% - 30px
		);
	margin-top: 56px;

	${FooterLicenseColumn} {
		flex-wrap: wrap;
		gap: 10px;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_tablet_medium,
						b: viewportPadding,
					})}) {
				grid-template-columns: 100%;

				${FooterContactColumn} {
					order: 1;
					flex-direction: row;
					flex-wrap: wrap;

					${FooterContactBox} {
						width: 33.33%;
						margin-bottom: 0;
					}
				}

				${FooterLinksColumn} {
					order: 2;
					flex-direction: row;
					flex-wrap: wrap;

					${FooterLabelStyled} {
						width: 100%;
					}

					a {
						width: 50%;
					}
				}

				${FooterLicenseColumn} {
					order: 4;
				}

				${FooterBrandColumn} {
					order: 3;
				}
			}
		`,
	)}
`;
